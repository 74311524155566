export interface User {
    id:string;
    uid:string;
    name: string;
    email:string;
    authProvider:string;
    active:boolean;
    token:string;
    photoUrl:string | null;        
    childUsers?:User[];
    actionDate:Date;
    actionUser:string;
    userType:string;
    status:string;
}
export class User implements User {
    constructor(user?:User)
    {
        if(user)
        {
            Object.assign(this,user);
        }
    }
    id:string = "";
    uid:string = "";
    name: string = "";
    email:string = "";
    authProvider:string = "";
    active:boolean = false;
    token:string = "";
    photoUrl: string | null = null;        
    childUsers?: User[]= [];
    actionDate:Date = new Date();
    actionUser:string="";
    userType: string="";
    status: string="";
}

export interface Product {
    id:string;
    actionDate:Date;
    actionUser:string;
    name:string;
    title:string;
    details:string;
    description:string;
    productType:string;
    entries:number;
    startDate:Date;
    endDate:Date;
    currentStatus:string;        
}

export class Product implements Product {
    constructor(product?:Product)
    {
        if(product)
        {
            Object.assign(this,product);
        }
    }
    id:string = "";
    actionDate:Date = new Date();
    actionUser:string= "";
    name:string= "";
    title:string= "";
    details:string= "";
    description:string= "";
    productType:string= "";
    entries:number= 10;
    startDate:Date = new Date();
    endDate:Date= new Date();
    currentStatus:string="";    
}
