import { makeAutoObservable, reaction } from "mobx";
import { ServerError } from "../models/serverError";

export default class CommonStore {
    error: ServerError | null = null;
    fixed:boolean = false;
    activePageName:string ="home";
    token: string | null = localStorage.getItem('jwt');
    dropdownMenuStyle: string = 'none';
    appLoaded = false;
    
    constructor() {
        makeAutoObservable(this);

        reaction(
            () => this.token,
            token => {
                if (token) {
                    localStorage.setItem('jwt', token)
                } else {
                    localStorage.removeItem('jwt')
                }
            }
        )
    }

    setServerError(error: ServerError) {
        this.error = error;
    }

    setToken = (token: string | null) => {
        this.token = token;
    }

    setAppLoaded = (value:boolean) => {
        this.appLoaded = value;
    }

    setActivePageName = (value:string) =>{
        this.activePageName = value;
    }
}