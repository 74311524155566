import { observer } from "mobx-react-lite";
import { useState,useEffect } from "react";
export default observer(function ResponsiveCategoryPage(cat,key) {     
    const currentyear = new Date().getFullYear();    
    return (
        <div className="col-12 col-lg-4 col-md-12 features-card" key={key}>
                    <img src={cat.categoryImage} alt={cat.name} />
                    <p className="mbr-text mbr-fonts-style display-5">
                        <strong>{cat.title}</strong></p>
                        <p className="mbr-text mbr-fonts-style display-5">
                        {cat.description}</p>
                </div>
    )
})