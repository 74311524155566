import { makeAutoObservable, runInAction } from "mobx";
import firebaseAgent from "../api/firebaseagent";
import { store } from "./store";
import { router } from "../router/Routes";
import { firestore } from "firebase-admin"
import { getDoc } from "firebase/firestore";
import { Category } from "../models/category";
export default class CategoryStore {
    category: Category = new Category();
    categories:Category[] = [];
    categoryreload = true;
    constructor() {
        makeAutoObservable(this)
    }    
    register = async (category: Category) => {
        try {
            const categoryDocRef = await firebaseAgent.categoryService.register(category);
            const categoryDocSnapshot = await getDoc(categoryDocRef);
            runInAction(() => this.category = categoryDocSnapshot.data() as Category);                     
        } catch (error) {
            throw error;
        }
    }
    manage = async (id:any,category: Category) => {
        try {
            const categoryDocSnapshot = await firebaseAgent.categoryService.manage(id,category);            
            runInAction(() => this.category = categoryDocSnapshot.data() as Category);
        } catch (error) {
            throw error;
        }
    }  
    loadCategoryList = async () => {
        try {
            const categoryDocSnapshot = await firebaseAgent.categoryService.categories();
            categoryDocSnapshot.forEach((message) => {
                this.setCategory(message.id,message.data() as Category)                
              });          
        } catch (error) {
            throw error;
        }
    }
    saveCategory = (category: Category) => {
        runInAction(() =>{
            category.actionDate = new Date(category.actionDate);
            this.category = category;
               let index = this.categories.findIndex(c=>c.id === category.id);
               if(index !== -1)
               {
                this.categories[index] = category;
               }else
               {
                this.categories.push(category);
               }
        });
    }
    setCategory = (id:any,category: Category) => {
        runInAction(() =>{
            category.id = id;
            category.actionDate = new Date(category.actionDate);
            this.category = category;
               let index = this.categories.findIndex(c=>c.id === category.id);
               if(index !== -1)
               {
                this.categories[index] = category;
               }else
               {
                this.categories.push(category);
               }
        });
    }    
    private getCategory = (id: string) => {
        return this.categories.find(c=>c.id === id);
    }
    loadCategory = async (id: string) => {
        let categoryObj = this.getCategory(id);
        if (categoryObj) {
            this.category = categoryObj;
            return categoryObj;
        }        
    }
    setCategoryReload = async (state:boolean) =>{
        runInAction(()=>{
            this.categoryreload = state;
        });
    }
    get categoryByName() {
        return this.categories.slice().sort();
    }
}