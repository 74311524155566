import { StringLiteral } from "typescript";

export interface Category {
    id:string;
    name: string;
    title: string;
    description?: string;    
    categoryType:string;
    categoryImage:string;
    status:string;    
    actionDate:Date;
    actionBy:string;
}
export class Category implements Category
{
    constructor(category?:Category)
    {
        if(category)
        {
            Object.assign(this,category);
        }
    }
    id:string = "";
    name: string="";
    title: string="";
    description?: string="";    
    categoryType:string="";
    categoryImage:string="";
    status:string="";
    actionDate:Date= new Date();
    actionBy:string="SuperAdmin";    
}