// import ActivityStore from "./activityStore";
import {createContext, useContext} from "react";
import CommonStore from "./commonStore";
import UserStore from "./userStore";
import ModalStore from "./modalStore";
import CategoryStore from "./categoryStore";
import ProductStore from "./productStore";

interface Store {    
    commonStore: CommonStore;
    userStore: UserStore;
    modalStore: ModalStore;    
    categoryStore:CategoryStore;
    productStore:ProductStore;    
}
export const store: Store = {    
    commonStore: new CommonStore(),
    userStore: new UserStore(),
    modalStore: new ModalStore(),
    categoryStore:new CategoryStore(),
    productStore:new ProductStore(),
}
export const StoreContext = createContext(store);
export function useStore() {
    return useContext(StoreContext);
}