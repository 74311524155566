import { makeAutoObservable, runInAction } from "mobx";
import firebaseAgent from "../api/firebaseagent";
import { store } from "./store";
import { router } from "../router/Routes";
import { firestore } from "firebase-admin"
import { getDoc } from "firebase/firestore";
import { Product } from "../models/company";
export default class ProductStore {
    product: Product = new Product();
    products:Product[] = [];
    productreload = true;
    constructor() {
        makeAutoObservable(this)
    }    
    register = async (product: Product) => {
        try {
            const productDocRef = await firebaseAgent.productService.register(product);
            const productDocSnapshot = await getDoc(productDocRef);
            runInAction(() => this.product = productDocSnapshot.data() as Product);                     
        } catch (error) {
            throw error;
        }
    }
    manage = async (id:any,product: Product) => {
        try {
            const productDocSnapshot = await firebaseAgent.productService.manage(id,product);            
            runInAction(() => this.product = productDocSnapshot.data() as Product);
        } catch (error) {
            throw error;
        }
    }
    loadProductList = async () => {
        try {
            const productDocSnapshot = await firebaseAgent.productService.products();
            productDocSnapshot.forEach((message) => {
                if(message !== undefined)
                    this.setProduct(message.id,message.data() as Product)                
              });          
        } catch (error) {
            throw error;
        }
    }
    saveProduct = (product: Product) => {
        runInAction(() =>{
            product.actionDate = new Date(product.actionDate);
            this.product = product;
               let index = this.products.findIndex(c=>c.id === product.id);
               if(index !== -1)
               {
                this.products[index] = product;
               }else
               {
                this.products.push(product);
               }
        });
    }
    setProduct = (id:any,product: Product) => {
        runInAction(() =>{
            if(product !== undefined)
            {
                product.id = id;
                product.actionDate = new Date(product.actionDate);
                this.product = product;
                   let index = this.products.findIndex(c=>c.id === product.id);
                   if(index !== -1)
                   {
                    this.products[index] = product;
                   }else
                   {
                    this.products.push(product);
                   }
            }
        });
    }    
    private getProduct = (id: string) => {
        return this.products.find(c=>c.id === id);
    }
    loadProduct = async (id: string) => {
        let productObj = this.getProduct(id);
        if (productObj) {
            this.product = productObj;
            return productObj;
        }        
    }
    setProductReload = async (state:boolean) =>{
        runInAction(()=>{
            this.productreload = state;
        });
    }
    get productByName() {
        return this.products.slice().sort();
        // sort((a, b) =>
        //     a.actionDate!.getTime() - b.actionDate!.getTime());;
    }
}