import { observer } from "mobx-react-lite";
import { useState,useEffect } from "react";
import { useStore } from "../../../app/stores/store";

export default observer(function ResponsiveProductPage() {
    const [toggleBtn, setToggleBtn] = useState(true);
  const toggle = () => setToggleBtn(val => !val);
  const { commonStore,modalStore,categoryStore} = useStore();
  useEffect(() => {    
  },[])
      const currentyear = new Date().getFullYear();
    const randomNumberInRange = (min, max) => { 
      return Math.floor(Math.random()  
              * (max - min + 1)) + min; 
  };
    return (
        <section data-bs-version="5.1" className="features6 cid-u7xjrtX9CL" id="features06-1m">
    <div className="container">
        <div className="row">
            
        </div>
        <div className="row">
            <div className="col-lg-12">
                <div className="row row-h">
                    <div className="card col-12 col-md-12 col-lg-12">
                        <div className="card-wrapper">
                            <div className="card-box align-center">                                
                            
          <div className="item features-image col-12 col-md-6 col-lg-12">
                <div className="item-wrapper" style={{color:'green'}}>
                Thank You so much for booking request our team will get back to you !
                </div>
            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    </div>
</section>
    )
})