import { observer } from 'mobx-react-lite';
import {
    Button,
    Container,
    Divider,
    Dropdown,
    Grid,
    Header,
    Icon,
    Image,
    Menu,
    Segment
  } from "semantic-ui-react";
  import "./WebHomePage.css";  
import { useStore } from '../../app/stores/store';
export default observer(function RefundCancellationPolicyPage() {
    const { commonStore,modalStore } = useStore();
    const currentyear = new Date().getFullYear();
    const randomNumberInRange = (min:number, max:number) => { 
        return Math.floor(Math.random()  
                * (max - min + 1)) + min;
    };
    return (
        <section data-bs-version="5.1" className="buildm5 features3 cid-ub6JFDjcR0" id="features3-1c">
  
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 content-head">
              <div className="card-wrapper mb-5">
                <div className="card-box align-center">
                  <h4 className="mbr-section-title mbr-fonts-style mb-0 display-5">Refund/Cancellation</h4>
                  <div className="line"></div>
                  <p className="mbr-text mbr-fonts-style mt-4 pb-4 display-7">We strive to provide you with the best products and service possible. However, we understand that there may be occasions when you need to cancel an order or request a refund. Please review our refund/cancellation policy below.</p>
      
      
                </div>
              </div>
            </div>
          </div>
          <div className="row content-row">
            <div className="item features-without-image col-12 col-md-6 col-lg-6 item-mb active">
              <div className="item-wrapper">
                <div className="title mb-3">
                  <span className="num mbr-fonts-style display-7">01</span>
                </div>
                <h4 className="card-title mbr-fonts-style pb-2 display-5">Order Cancellation</h4>
                <p className="mbr-text card-text mbr-fonts-style mb-0 display-7">You may cancel your order at any time before it has been processed and shipped. To cancel an order, please contact us as soon as possible with your order details. If your order has already been processed or shipped, we may not be able to cancel it, but we will do our best to accommodate your request.<br/></p>
                <div className="line2"></div>
              </div>
            </div>
            <div className="item features-without-image col-12 col-md-6 col-lg-6 item-mb">
              <div className="item-wrapper">
                <div className="title mb-3">
                  <span className="num mbr-fonts-style display-7">02</span>
                </div>
                <h4 className="card-title mbr-fonts-style pb-2 display-5">Refund Policy (Damaged or Defective)</h4>
                <p className="mbr-text card-text mbr-fonts-style mb-0 display-7">If you receive damaged or defective products, please contact us immediately with details and photographic evidence of the damage. We will assess the situation and, if appropriate, provide a replacement or issue a refund.<br/></p>
                <div className="line2"></div>
              </div>
            </div>
            <div className="item features-without-image col-12 col-md-6 col-lg-6 item-mb">
              <div className="item-wrapper">
                <div className="title mb-3">
                  <span className="num mbr-fonts-style display-7">03</span>
                </div>
                <h4 className="card-title mbr-fonts-style pb-2 display-5">Refund Policy (Incorrect Items)</h4>
                <p className="mbr-text card-text mbr-fonts-style mb-0 display-7">If you receive incorrect items in your order, please contact us within 7 days of receiving your shipment. We will arrange for the correct items to be sent to you or issue a refund for the incorrect items returned to us in their original condition.<br/></p>
                <div className="line2"></div>
              </div>
            </div><div className="item features-without-image col-12 col-md-6 col-lg-6 item-mb">
              <div className="item-wrapper">
                <div className="title mb-3">
                  <span className="num mbr-fonts-style display-7">04</span>
                </div>
                <h4 className="card-title mbr-fonts-style pb-2 display-5">Refund Policy (Quality Concerns)</h4>
                <p className="mbr-text card-text mbr-fonts-style mb-0 display-7">If you have concerns about the quality of our products, please contact us with details of the issue. We may request additional information or samples to investigate the matter further. If the quality concern is valid, we will offer a replacement or refund. <br/></p>
                <div className="line2"></div>
              </div>
            </div>
            <div className="item features-without-image col-12 col-md-6 col-lg-6 item-mb">
              <div className="item-wrapper">
                <div className="title mb-3">
                  <span className="num mbr-fonts-style display-7">05</span>
                </div>
                <h4 className="card-title mbr-fonts-style pb-2 display-5">Refund Processing</h4>
                <p className="mbr-text card-text mbr-fonts-style mb-0 display-7">Refunds will be processed within 7 days of approval and issued via the original payment method used for the purchase. Please note that it may take additional time for the refund to appear in your account, depending on your financial institution's processing times. <br/></p>
                <div className="line2"></div>
              </div>
            </div>
              <div className="item features-without-image col-12 col-md-6 col-lg-6 item-mb">
              <div className="item-wrapper">
                <div className="title mb-3">
                  <span className="num mbr-fonts-style display-7">06</span>
                </div>
                <h4 className="card-title mbr-fonts-style pb-2 display-5">Non-Refundable Items</h4>
                <p className="mbr-text card-text mbr-fonts-style mb-0 display-7">Certain items may not be eligible for a refund, including products that have been opened, used, or damaged by the customer, products that are not in their original condition or packaging, custom or personalized products made to order<br/></p>
                <div className="line2"></div>
              </div>
            </div>
      
      
          </div>
        </div>
      </section>
    )
})