import { createBrowserRouter, Navigate, RouteObject } from "react-router-dom";
import NotFound from "../../features/errors/NotFound";
import ServerError from "../../features/errors/ServerError";
import TestErrors from "../../features/errors/TestError";
import App from "../layout/App";
import ResponsiveCategoriesPage from "../../features/home/categories/ResponsiveCategoriesPage";
import PrivacyPolicyPage from "../../features/home/PrivacyPolicyPage";
import ResponsiveProductPage from "../../features/home/categories/ResponsiveProductPage";
import TermsConditionsPage from "../../features/home/TermsConditionsPage";
import RefundCancellationPolicyPage from "../../features/home/RefundCancellationPolicyPage";
import ResponsiveOrderPage from "../../features/home/categories/ResponsiveOrderPage";
import AboutUsPage from "../../features/home/AboutUsPage";
import ContactUsPage from "../../features/home/ContactUsPage";
import ResponsiveHeaderPage from "../../features/home/Header/ResponsiveHeaderPage";
import ResponsiveFooterPage from "../../features/home/Footer/ResponsiveFooterPage";

export const routes: RouteObject[] = [                          
            {path: 'not-found', element: <><ResponsiveHeaderPage /><NotFound /><ResponsiveFooterPage /></>},    
             {path: '*', element: <Navigate replace to='/not-found' />},
    {
        path:'/',
        element: <App />,
        children: [
        ]
    },
    {
        path:'/aboutus',
        element:<><ResponsiveHeaderPage /><AboutUsPage /><ContactUsPage /><ResponsiveFooterPage /></>
    },
    {
        path:'/contactus',
        element:<><ResponsiveHeaderPage /><ContactUsPage /><ResponsiveFooterPage /></>
    },
    {
        path:'/privacy',
        element:<><ResponsiveHeaderPage /><PrivacyPolicyPage /><ContactUsPage /><ResponsiveFooterPage /></>
    },
    {
        path:'/terms',
        element:<><ResponsiveHeaderPage /><TermsConditionsPage /><ContactUsPage /><ResponsiveFooterPage /></>
    },
    {
        path:'/refund',
        element:<><ResponsiveHeaderPage /><RefundCancellationPolicyPage /><ContactUsPage /><ResponsiveFooterPage /></>
    },
    {
        path:'/thankyou',
        element:<><ResponsiveHeaderPage /><ResponsiveOrderPage /><ContactUsPage /><ResponsiveFooterPage /></>
    },
    {
        path:'/product/:id',
        element:<><ResponsiveHeaderPage /><ResponsiveProductPage /><ContactUsPage /><ResponsiveFooterPage /></>
    }
]

export const router = createBrowserRouter(routes);