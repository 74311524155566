import { observer } from 'mobx-react-lite';
import {
    Button,
    Container,
    Divider,
    Dropdown,
    Grid,
    Header,
    Icon,
    Image,
    Menu,
    Segment
  } from "semantic-ui-react";
  import "./WebHomePage.css";  
import { useStore } from '../../app/stores/store';
export default observer(function PrivacyPolicyPage() {
    const { commonStore,modalStore } = useStore();
    const currentyear = new Date().getFullYear();    
    const randomNumberInRange = (min:number, max:number) => { 
      return Math.floor(Math.random()  
              * (max - min + 1)) + min; 
  };
    return (
        <section data-bs-version="5.1" className="buildm5 features3 cid-ub1vWmjR5D" id="features3-17">

  

  
  
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-12 content-head">
        <div className="card-wrapper mb-5">
          <div className="card-box align-center">
            <h4 className="mbr-section-title mbr-fonts-style mb-0 display-5">Privacy Policy</h4>
            <div className="line"></div>
            <p className="mbr-text mbr-fonts-style mt-4 pb-4 display-7">We are committed to protecting your privacy and safeguarding your personal information.</p>


          </div>
        </div>
      </div>
    </div>
    <div className="row content-row">
      <div className="item features-without-image col-12 col-md-6 col-lg-6 item-mb active">
        <div className="item-wrapper">
          <div className="title mb-3">
            <span className="num mbr-fonts-style display-7">01</span>
          </div>
          <h4 className="card-title mbr-fonts-style pb-2 display-5">Information We Collect</h4>
          <p className="mbr-text card-text mbr-fonts-style mb-0 display-7">We may collect personal information from you when you visit our website, place an order, or communicate with us through email, phone, or other channels. This information may include your name, contact information, shipping address, payment details, and any other information you provide to us.<br/></p>
          <div className="line2"></div>
        </div>
      </div>
      <div className="item features-without-image col-12 col-md-6 col-lg-6 item-mb">
        <div className="item-wrapper">
          <div className="title mb-3">
            <span className="num mbr-fonts-style display-7">02</span>
          </div>
          <h4 className="card-title mbr-fonts-style pb-2 display-5">How We Use Your Information</h4>
          <p className="mbr-text card-text mbr-fonts-style mb-0 display-7">We use the information we collect from you to process orders, fulfill requests, provide customer support, and improve our products and services. We may also use your information to communicate with you about your orders, promotions, updates, and other relevant information.<br/></p>
          <div className="line2"></div>
        </div>
      </div>
      <div className="item features-without-image col-12 col-md-6 col-lg-6 item-mb">
        <div className="item-wrapper">
          <div className="title mb-3">
            <span className="num mbr-fonts-style display-7">03</span>
          </div>
          <h4 className="card-title mbr-fonts-style pb-2 display-5">Data Security</h4>
          <p className="mbr-text card-text mbr-fonts-style mb-0 display-7">We take the security of your personal information seriously and implement appropriate measures to protect it from unauthorized access, disclosure, alteration, or destruction. Our website is secured with SSL encryption to ensure that your information is transmitted securely. We also restrict access to your information to authorized personnel only.<br/></p>
          <div className="line2"></div>
        </div>
      </div><div className="item features-without-image col-12 col-md-6 col-lg-6 item-mb">
        <div className="item-wrapper">
          <div className="title mb-3">
            <span className="num mbr-fonts-style display-7">04</span>
          </div>
          <h4 className="card-title mbr-fonts-style pb-2 display-5">Cookies</h4>
          <p className="mbr-text card-text mbr-fonts-style mb-0 display-7">Our website may use cookies to enhance your browsing experience and collect information about how you interact with our website. Cookies are small text files stored on your device that track your preferences and activities. You can adjust your browser settings to refuse cookies or alert you when cookies are being sent, but this may affect your ability to access certain features of our website. <br/></p>
          <div className="line2"></div>
        </div>
      </div>
      <div className="item features-without-image col-12 col-md-6 col-lg-6 item-mb">
        <div className="item-wrapper">
          <div className="title mb-3">
            <span className="num mbr-fonts-style display-7">05</span>
          </div>
          <h4 className="card-title mbr-fonts-style pb-2 display-5">Third-Party Disclosure</h4>
          <p className="mbr-text card-text mbr-fonts-style mb-0 display-7">We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except as required by law or as necessary to provide our services. We may share your information with trusted third-party service providers who assist us in operating our website, conducting business, or servicing you. <br/></p>
          <div className="line2"></div>
        </div>
      </div>
        <div className="item features-without-image col-12 col-md-6 col-lg-6 item-mb">
        <div className="item-wrapper">
          <div className="title mb-3">
            <span className="num mbr-fonts-style display-7">06</span>
          </div>
          <h4 className="card-title mbr-fonts-style pb-2 display-5">Changes to Privacy Policy</h4>
          <p className="mbr-text card-text mbr-fonts-style mb-0 display-7">We reserve the right to modify or update this privacy policy at any time. Changes will be effective immediately upon posting on this website. It is your responsibility to review this privacy policy periodically for updates. <br/></p>
          <div className="line2"></div>
        </div>
      </div>


    </div>
  </div>
</section>      
    )
})