import { Outlet, ScrollRestoration, useLocation } from "react-router-dom";
import { useStore } from "../stores/store";
import { useEffect, useState } from "react";
import LoadingComponent from "./LoadingComponent";
import ModalContainer from "../common/modals/ModalContainer";
import { ToastContainer } from "react-toastify";
import { observer } from "mobx-react-lite";
import ResponsiveHeaderPage from "../../features/home/Header/ResponsiveHeaderPage";
import ResponsiveFooterPage from "../../features/home/Footer/ResponsiveFooterPage";
import ResponsiveHomePage from "../../features/home/ResponsiveHomePage";
import ContactUsPage from "../../features/home/ContactUsPage";

function App() {
  const location = useLocation();
  const { commonStore,userStore } = useStore();  
  useEffect(() => {
    if (commonStore.token) {      
     userStore.getUser().finally(() => commonStore.setAppLoaded(true))
     //commonStore.setAppLoaded()
    } else {
      commonStore.setAppLoaded(true);
    }
  }, [commonStore, userStore])
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const [toggleBtn, setToggleBtn] = useState(true);
  const toggle = () => setToggleBtn(val => !val);
  if (!commonStore.appLoaded) return <LoadingComponent content='Loading app...' />

  return (
    <>    
      <ScrollRestoration />
      <ModalContainer />
      <ToastContainer position='bottom-right' hideProgressBar theme='colored' />      
      <ResponsiveHeaderPage />
      {
      location.pathname === '/' ? (<>      
      <ResponsiveHomePage />      
      </>):
      (
        <Outlet />
      )}
      <ContactUsPage />
      <ResponsiveFooterPage />
    </>
  );
}
export default observer(App);
