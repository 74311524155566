import { observer } from "mobx-react-lite";
import { useState,useEffect } from "react";
import { useStore } from "../../../app/stores/store";
import { useNavigate, useParams } from "react-router-dom";
import { GoogleAuthProvider, signInWithPopup,signInWithEmailAndPassword } from "firebase/auth";
import { auth, db} from "../../../firestore-config";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import {quantityvalue} from "../../../app/common/options/categoryOptions";

export default observer(function ResponsiveProductPage() {
    const [toggleBtn, setToggleBtn] = useState(true);    
  const toggle = () => setToggleBtn(val => !val);
  const { commonStore,modalStore,productStore,
    userStore:{user,setUser}} = useStore();
  const navigate = useNavigate();
  const {loadProduct,product,setProduct,setProductReload} = productStore;
  const { id } = useParams();
  const [message, setMessage] = useState("Please go next to login in with your google account to proceed next steps");
  const [quantity, setQuantity] = useState(1);
  const [totalamount, setTotalAmount] = useState(0);
  const googleProvider = new GoogleAuthProvider();
  const signInWithGoogle = async () => {
    try {
      const res = await signInWithPopup(auth, googleProvider);
      const user = res.user;      
      const q = query(collection(db, "users"), where("uid", "==", user.uid));
      const docs = await getDocs(q);
      const newuser = 
        {
            uid: user.uid,
          name: user.displayName,
          token: user.refreshToken,
          email:user.email,
          authProvider: "google",
          email: user.email,
          active: false,
          photoUrl:user.photoURL
        }
      if (docs.docs.length === 0) {            
        await addDoc(collection(db, "users"),newuser);            
      }
      setUser(newuser);
      setMessage(`Welcome, ${newuser.name}`)
      navigate('/thankyou');
    } catch (err) {
      console.error(err);      
    }
  };
  useEffect(() => {
    if (id)
        {
            loadProduct(id).then(product => {              
              setProduct(product);
              setTotalAmount(parseInt(quantity) * product.productPrice);
            })                        
        }        
        if(product === null || product === undefined || product.id === "")
                    navigate('/home');        
  },[])  
      const currentyear = new Date().getFullYear();
    const randomNumberInRange = (min, max) => { 
      return Math.floor(Math.random()  
              * (max - min + 1)) + min; 
  };
    return (
        <>
        <section data-bs-version="5.1" className="form01 devicem5 cid-ub1qQYnEbG" id="form01-z">

<div className="container-fluid">
    <div className="row">
        <div className="col-12 col-lg-6">
            <div className="image-wrapper">
                <img src={`/${product.productImage}`} alt={product.name} />
            </div>
        </div>
        <div className="col-12 col-lg-5">
            <div className="content-wrapper">
                <div className="desc-wrapper">
                    <p className="mbr-desc mbr-fonts-style display-4">
                        Order Detail</p>
                </div>
                <div className="mbr-form" data-form-type="formoid">
                <div className="dragArea row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <h5 className="mbr-section-title mbr-fonts-style display-5">{product.name}</h5>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <p className="mbr-text mbr-fonts-style display-7">
                                {product.title}
                                </p>
                            </div>
                            <div data-for="email" className="col-lg-12 col-md-12 col-sm-12 form-group mb-3 mb-3 display-4">
                            INR {product.productPrice} Per Kg
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 form-group mb-3 mb-3 display-4">{quantity} {product.name} = <strong> {totalamount} INR </strong></div>
                            <select className="form-control display-4" onChange={(e)=>{
                                setQuantity(parseInt(e.target.value));                                
                                setTotalAmount(parseInt(e.target.value) * product.productPrice);
                            }}>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                    <option value="4">Four</option>
                                    </select>
                            <div className="col mbr-section-btn"><button type="button" className="btn btn-white-outline display-4"><span className="socicon socicon-google mbr-iconfont mbr-iconfont-btn" style={{color: "rgb(255, 3, 3)"}} onClick={signInWithGoogle}></span>                                    
                                    Order now
                                </button></div>
                        </div>
                </div>
            </div>
        </div>
    </div>
</div>
</section>      
        </>
    )
})