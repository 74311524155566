import { Link } from "react-router-dom";
import { Button, Header, Icon, Segment } from "semantic-ui-react";

export default function NotFound() {
    return (

<section data-bs-version="5.1" className="features6 cid-u7xjrtX9CL" id="features06-1m">
    <div className="container">
        <div className="row">
            
        </div>
        <div className="row">
            <div className="col-lg-12">
                <div className="row row-h">
                    <div className="card col-12 col-md-12 col-lg-12">
                        <div className="card-wrapper">
                            <div className="card-box align-center">                                
                            
          <div className="item features-image col-12 col-md-6 col-lg-12">
                <div className="item-wrapper" style={{color:'red'}}>
                Oops - we've looked everywhere but could not find what you are looking for!
                </div>
            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    </div>
</section>
    )
}